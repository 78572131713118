import {
  AccordionTitle,
  AccordionContent,
} from 'semantic-ui-react'
import { useDispatch } from 'react-redux';
import React, {useState} from 'react';
import { getFolderContent } from '../actions';
import { FolderContentAccordian } from '../components/FolderContentAccordian';
import '../styles/accordianstyles.less'

export const Folder = ({index, folderInfo, isActive, clickHandler, node}) => {
  const [folderContent, setFolderContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  React.useEffect(()=>{
    if(isActive){
      dispatch(getFolderContent(folderInfo.id, node)).then(
        result => setFolderContent(result)
      ).catch(function (err){
        console.error(err);
      }).finally(() => {
            setLoading(false)
        })
    }
  },[dispatch, isActive]);


  let title = folderInfo.text;
  let folderIcon = "ri-folder-fill";

  if(isActive){
    folderIcon = "ri-folder-open-fill";
    return (
      <>
        <AccordionTitle
          className='accordian-title'
          active={isActive}
          index={index}
          onClick={clickHandler}
        >
          <i className={folderIcon}></i>
          <a href={folderInfo.a_attr.href}> { title }  </a>
        </AccordionTitle>
        <AccordionContent active={isActive} className='accordian-indent' >
            <FolderContentAccordian folderContent={folderContent} loading={loading} node={node}/>
        </AccordionContent>
      </>
    );
  }

  return (
    <>
      <AccordionTitle
        className='accordian-title'
        active={isActive}
        index={index}
        onClick={clickHandler}
      >
          <i className={folderIcon}></i>
          <a href={ folderInfo.a_attr.href }> { title }</a>
      </AccordionTitle>
      <AccordionContent active={isActive}>
      </AccordionContent>
    </>
  );
}
