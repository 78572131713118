import 'remixicon/fonts/remixicon.css'
import '../styles/accordianstyles.less'

export const File = ({fileInfo}) => {
  return  (
    <>
      <div className='file'>
        <i className="ri-file-line"></i>
        <a href={ fileInfo.a_attr.href }> { fileInfo.text }</a>
      </div>
    </>
  );
}
