import {useState} from 'react';
import {
  Accordion,
} from 'semantic-ui-react'
import { File } from "./File"
import { Folder } from "./Folder"
import '../styles/accordianstyles.less'

export const FolderContentAccordian = ({ folderContent, loading, node}) => {

  if (loading){
    return <p className='nocontent'>Loading...</p>;
  }

  if (folderContent.length === 0){
    return <p className='nocontent'>This Folder has no content</p>
  }

  const [activeProjects, setActiveProjects] = useState(
    new Array(folderContent.length).fill(false)
  );

  const openProject = (index) => {
    const newActiveProjectList = [...activeProjects];
    newActiveProjectList[index] = newActiveProjectList[index] === false;
    setActiveProjects(newActiveProjectList);
  };

  return <>
      <Accordion>
        {folderContent.map((content, i)=>{
          if(content.children === false){
            return <File key={i} fileInfo={content} />
          } else{
            return <Folder key={content.text}
                           index={i}
                           folderInfo={content}
                           isActive={activeProjects[i]}
                           clickHandler={() => openProject(i)}
                           node={node}/>
          }
        })}
      </Accordion>
  </>

}


