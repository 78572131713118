import { GET_USER_PROJECTS, GET_FOLDER_CONTENT} from './constants';

export function getUserProjects() {
  return {
    type: GET_USER_PROJECTS,
    request: {
      op: 'get',
      path: `/project_root.json`,
    },
  };
}

export function getFolderContent(id, node){
  const folderPath = node === 'main' ? `/project_child.json?id=${id}`
    : `/project_child.json?id=${id}&node=${node}`;
  return {
    type: GET_FOLDER_CONTENT,
    request: {
      op: 'get',
      path: folderPath,
    }
  };
}
