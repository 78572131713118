import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getUserProjects } from '../actions';
import 'remixicon/fonts/remixicon.css';
import { FolderContentAccordian } from '../components/FolderContentAccordian';
import '../styles/mainpage.less'

const UserProjectsView = () => {
  const [projects, setProjects] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const result = await dispatch(getUserProjects());
        setProjects(result);
      } catch (err) {
        console.error('Error fetching user projects:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, [dispatch]);

  if (loading) {
    return <div>Loading projects...</div>;
  }

  if (projects === null || projects.nodes.length === 0){
    return (
      <div>
        <a href={`/login?return_url=/my-projects`}>Log in</a> to view projects
      </div>
    );
  }
  return (
    <>
      <FolderContentAccordian folderContent={projects.nodes[0].projects} node={"main"}/>
      {projects?.nodes?.length > 1 && (
        <div>
          <h3 className='nodeprojects'>Node Projects</h3>
          {projects.nodes.slice(1).map((node) => (
            <div key={node.node}>
              <h4 className='node'>{node.node.toUpperCase()}</h4>
              <FolderContentAccordian folderContent={node.projects} node={node.node}/>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default UserProjectsView;
